<i18n>
{
  "ru": {
    "title": "Демо доступ к психологическим тестам",
    "description": "На странице представлены ознакомительные версии тестов с примерами отчетов в нескольких представлениях.<br />Если вы заинтересовались одним из них, обратитесь к нашим менеджерам или в техническую поддержку.",
    "note": "Примечание: примеры отчетов не зависят от ответов в демонстрационном тесте, отчеты в конце опроса всегда одинаковые.",
    "testExampleButton": "Пример теста",
    "tests": {
      "title": {
        "critical": "Критическое мышление",
        "burnout": "Выгорание",
        "frontStaff": "FrontStaff",
        "leadFive": "Лидерская Пятёрка Компетенций (ЛПК)",
        "personalFactor": "11 личностных факторов (11ЛФ)",
        "bigFive": "Большая Пятёрка Компетенций (БПК)",
        "safetyStart": "SafetyStart",
        "leaderCase": "LeaderCase",
        "deMetrics": "DeMetrics"
      },
      "description": {
        "critical": "Экспресс-оценка способности к критическому анализу информации",
        "burnout": "Экспресс-оценка профессионального выгорания",
        "frontStaff": "Экспресс-тест базовых компетенций и интеллектуального потенциала для тех, кто работает с людьми",
        "leadFive": "Экспресс-оценка компетенций лидеров: менеджеров проектов, руководителей, предпринимателей, антикризисных менеджеров",
        "personalFactor": "Экспресс-тест потенциала к продажам",
        "bigFive": "Экспресс-оценка компетенций работников массовых специальностей",
        "safetyStart": "Экспресс-тест для отбора и оценки сотрудников на предприятиях с повышенным уровнем опасности",
        "leaderCase": "Кейсовый экспресс-тест лидерского потенциала",
        "deMetrics": "Экспресс-оценка десяти значимых факторов мотивации и удовлетворённости в работе"
      }
    }
  },
  "en": {
    "title": "Demo access to psychological tests",
    "description": "The page contains trial versions of tests with example reports in several views.<br />If you are interested in one of them, contact our managers or technical support.",
    "note": "Note: The sample reports are independent of the answers in the demo test, the reports at the end of the survey are always the same.",
    "testExampleButton": "Test example",
    "tests": {
      "title": {
        "critical": "Critical thinking",
        "burnout": "Burnout",
        "frontStaff": "FrontStaff",
        "leadFive": "Leadership Five Competencies",
        "personalFactor": "11 personality factors (11ЛФ)",
        "bigFive": "Big Five Competencies",
        "safetyStart": "SafetyStart",
        "leaderCase": "LeaderCase",
        "deMetrics": "DeMetrics"
      },
      "description": {
        "critical": "Express assessment of the ability to critically analyze information",
        "burnout": "Express assessment of professional burnout",
        "frontStaff": "Express test of basic competencies and intellectual potential for those who work with people",
        "leadFive": "Express assessment of the competencies of leaders: project managers, managers, entrepreneurs, anti-crisis managers",
        "personalFactor": "Express test of sales potential",
        "bigFive": "Express assessment of the competencies of employees of mass specialties",
        "safetyStart": "Express test for selecting and assessing employees at enterprises with a high level of danger",
        "leaderCase": "Express case test of leadership potential",
        "deMetrics": "Express assessment of ten significant factors of motivation and job satisfaction"
      }
    }
  }
}
</i18n>
<template>
  <div>
    <div class="mb-8">
      <app-title size="middle" weight="bold" class="mb-4">{{ $t('title') }}</app-title>
      <!-- eslint-disable vue/no-v-html -->
      <p v-html="$t('description')"></p>
      <!--eslint-enable-->
      <p class="grey--text">{{ $t('note') }}</p>
    </div>
    <v-card v-for="(test, index) in tests" :key="index" flat color="info" class="mb-2">
      <v-card-text>
        <v-row>
          <v-col cols="12" sm="8">
            <app-title size="default" classes="mb-2">{{ test.title }}</app-title>
            <p class="grey--text">{{ test.description }}</p>
          </v-col>
          <v-col cols="12" sm="auto" class="ms-auto">
            <v-btn
              block
              small
              :outlined="$vuetify.breakpoint.mobile"
              :text="!$vuetify.breakpoint.mobile"
              color="blue"
              @click="openDemoTestingDialog(test)"
            >
              <v-icon left v-text="'mdi-arrow-right'"></v-icon>
              <span>{{ $t('testExampleButton') }}</span>
            </v-btn>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
    <app-demo-testing-personal-dialog
      :is-visible="isDialogVisible.demoTestingPersonal"
      :test="selectedTest"
      @toggle-visible="toggleVisibleDialog"
    />
  </div>
</template>

<script>
import {dialogsMixin} from '@/mixins/dialogs';

import AppDemoTestingPersonalDialog from '@/components/pages/company/demo/personal-tests/dialogs/DemoTestingPersonalDialog';

export default {
  name: 'AppCompanyDemoPersonalTests',
  components: {
    AppDemoTestingPersonalDialog,
  },
  mixins: [dialogsMixin],
  data() {
    return {
      tests: [
        {
          title: this.$t('tests.title.critical'),
          description: this.$t('tests.description.critical'),
          href: 'https://client.maintest.ru/m-tests/?t=f207503515ff05f4',
        },
        {
          title: this.$t('tests.title.burnout'),
          description: this.$t('tests.description.burnout'),
          href: 'https://client.maintest.ru/m-tests/?t=b10504ba82be046b',
        },
        {
          title: this.$t('tests.title.frontStaff'),
          description: this.$t('tests.description.frontStaff'),
          href: 'https://client.maintest.ru/m-tests/?t=6dc75cd551d80fa6',
        },
        {
          title: this.$t('tests.title.leadFive'),
          description: this.$t('tests.description.leadFive'),
          href: 'https://client.maintest.ru/m-tests/?t=5c584774dba44b94',
        },
        {
          title: this.$t('tests.title.personalFactor'),
          description: this.$t('tests.description.personalFactor'),
          href: 'https://client.maintest.ru/m-tests/?t=ee8e17811ff0dc8f',
        },
        {
          title: this.$t('tests.title.bigFive'),
          description: this.$t('tests.description.bigFive'),
          href: 'https://client.maintest.ru/m-tests/?t=4b105c67455a8ad8',
        },
        {
          title: this.$t('tests.title.safetyStart'),
          description: this.$t('tests.description.safetyStart'),
          href: 'https://client.maintest.ru/m-tests/?t=33fa000cd26f25b3',
        },
        {
          title: this.$t('tests.title.leaderCase'),
          description: this.$t('tests.description.leaderCase'),
          href: 'https://client.maintest.ru/m-tests/?t=039f5121a92dc327',
        },
        {
          title: this.$t('tests.title.deMetrics'),
          description: this.$t('tests.description.deMetrics'),
          href: 'https://client.maintest.ru/m-tests/?t=cefa786389519352',
        },
      ],
      isDialogVisible: {
        demoTestingPersonal: false,
      },
      selectedTest: {},
    };
  },
  methods: {
    openDemoTestingDialog(test) {
      this.selectedTest = test;
      this.toggleVisibleDialog({name: 'demoTestingPersonal', state: true});
    },
  },
};
</script>
